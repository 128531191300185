import { useEffect } from "react";

const CallRailEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.callrail.com/companies/329128392/36c303f077e937ef02c5/12/swap.js"; // Replace with your actual CallRail script URL
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default CallRailEmbed;