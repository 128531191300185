import React, { useEffect } from "react";
import "./SellMyHome.scss";
import { Link } from "react-router-dom";

import Appbar from "../../components/Appbar/Appbar";
import Footer from "../../components/Footer/Footer";

import { Box, Typography, Button } from "@mui/material";
import { useAnalytics } from "../../customHooks/reactGA";

import iconLight from '../../assets/carp_house_plain.png';
import iconDark from '../../assets/carp-house-plain-dark.png';

const SellMyHome = (props) => {
  const { trackPageView, trackEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('/sell-my-home');
  }, [trackPageView]);

  return (
    <Box>
      <Appbar colorMode={props.colorMode} theme={props.theme} />
      <section
        className="landing"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.dark
              : '#e4e4e4',
          backgroundImage:
            props.theme.palette.mode === "dark"
              ? `url(${iconDark})`
              : `url(${iconLight})`
        }}
      >
        <div className="flex-container main-text-container">
          <div>
            <h1
              className="heading-title l1"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.warning.main
                    : props.theme.palette.primary.main,
              }}
            >
              We buy As-is… and we mean it!
            </h1>
            <p
              className="heading-title l2"
              style={{
                color:
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark,
              }}
            >
              At CarpHomes, we believe every home has value. We’re ready to make you a fair, all-
              cash offer in the exact as-is condition of your home. You don’t need to worry about
              making repairs, cleaning, or even staging your home. We can save you time, money,
              and stress.
            </p>
          </div>
        </div>
      </section>

      <Box
        className="smh-heading-container"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        mt={10}
        mb={10}
      >
        <Box flex={1} m={2.5}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.warning.main
                : props.theme.palette.warning.main
            }
          >
            No Realtor Fees, No Closing Costs
          </Typography>
          <Typography
            lineHeight={2.5}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            When you sell your home to CarpHomes, you won’t pay any realtor commissions,
            and there are no hidden closing costs. No need for any complicated calculations or
            last-minute surprises at the closing table. With our offers, what you see is what you get!
          </Typography>
        </Box>
        <Box flex={1} m={2.5}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.warning.main
                : props.theme.palette.warning.main
            }
          >
            Local Service, Personal Touch
          </Typography>
          <Typography
            lineHeight={2.5}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            Being local means we care about the community and its people. Unlike big, national
            companies, we offer a personal, hands-on approach. A member of our team will meet
            you in person to discuss your needs and walk you through the process, ensuring that
            you feel comfortable and well-informed every step of the way. When you work with
            CarpHomes, you’re not just another transaction — you’re a valued neighbor.
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={0}
        bgcolor={
          props.theme.palette.mode === "dark"
            ? props.theme.palette.primary.dark
            : '#e4e4e4'
        }
      >
        <div className="content-wrapper">

          <Box mb={15}>
            <Box
              sx={{ width: "87.5%", margin: "0 auto", textAlign: "center" }}
            >
              <Typography
                variant="h4"
                fontFamily={"karla"}
                mb={2.5}
                mt={15}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.secondary.light
                    : props.theme.palette.primary.dark
                }
              >
                How It Works
              </Typography>
              <Typography mb={10}
                lineHeight={2.5}
                color={
                  props.theme.palette.mode === "dark"
                    ? props.theme.palette.common.white
                    : props.theme.palette.primary.dark
                }
              >
                Selling your home to CarpHomes is simple, fast, and stress-free. We make the process
                as easy as possible, from start to finish, so you can get the cash you need without the
                hassle of traditional real estate transactions. Here's how it works:
              </Typography>
            </Box>

            <section
              className="how-it-works"
            >
              <div className='flex-card-container'>
                <Box
                  className='flex-card'
                  border={
                    props.theme.palette.mode === "dark"
                      ? `2px solid ${props.theme.palette.secondary.main}`
                      : `2px solid ${props.theme.palette.primary.main}`
                  }
                >
                  <div className="card-number-wrapper">
                    <Box
                      className='card-number'
                      backgroundColor={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.primary.main
                      }
                    >
                      <h1>1</h1>
                    </Box>
                  </div>
                  <div className="card-text-container">
                    <Typography
                      fontSize={'1.25rem'}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      <span>Contact Us</span>
                    </Typography>
                    <Typography mt={1.25}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      The first step is easy — just reach out to us! You can fill out our online form, give us a
                      call, or send us a message. As a local Indiana business, we are always just a phone
                      call away, ready to assist you with any questions you may have.
                      <br />
                      <br />
                      We'll listen to your situation and gather a few details about your property — no
                      pressure, no obligations. Whether you're dealing with a foreclosure, a house that&#39;s in
                      need of repairs, or simply need to sell quickly, we’re here to help.
                    </Typography>
                  </div>
                </Box>
                <Box
                  className='flex-card'
                  border={
                    props.theme.palette.mode === "dark"
                      ? `2px solid ${props.theme.palette.secondary.main}`
                      : `2px solid ${props.theme.palette.primary.main}`
                  }
                >
                  <div className="card-number-wrapper">
                    <Box
                      className='card-number'
                      backgroundColor={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.primary.main
                      }
                    >
                      <h1>2</h1>
                    </Box>
                  </div>
                  <div className="card-text-container">
                    <Typography
                      fontSize={'1.25rem'}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      <span>We Schedule a No-Obligation Property Visit</span>
                    </Typography>
                    <Typography mt={1.25}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      After you contact us, one of our team members — who’s local and familiar with your
                      area — will schedule a time to meet with you in person. This allows us to evaluate the
                      property and make sure we understand its condition, location, and any unique aspects
                      that may affect its value.
                      <br />
                      <br />
                      No need to worry about cleaning, fixing, or preparing your home. We’ll meet you at your
                      convenience, with no pressure to sell.
                    </Typography>
                  </div>
                </Box>
                <Box
                  className='flex-card'
                  border={
                    props.theme.palette.mode === "dark"
                      ? `2px solid ${props.theme.palette.secondary.main}`
                      : `2px solid ${props.theme.palette.primary.main}`
                  }
                >
                  <div className="card-number-wrapper">
                    <Box
                      className='card-number'
                      backgroundColor={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.primary.main
                      }
                    >
                      <h1>3</h1>
                    </Box>
                  </div>
                  <div className="card-text-container">
                    <Typography
                      fontSize={'1.25rem'}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      <span>Receive Your Fair Cash Offer</span>
                    </Typography>
                    <Typography mt={1.25}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      Once we’ve seen the property, we’ll make you a fair, all-cash offer based on our
                      assessment. Our offers are no-obligation — you’re free to accept or decline, and we
                      won’t pressure you in any way. We pride ourselves on being transparent and honest
                      throughout the process, so you can be rest assured that the offer we make is a fair
                      reflection of your home’s value.
                      <br />
                      <br />
                      Since we are buying the property directly from you, there are no realtor commissions
                      or hidden fees involved. Everything is upfront, and there are no surprises.
                    </Typography>
                  </div>
                </Box>
                <Box
                  className='flex-card'
                  border={
                    props.theme.palette.mode === "dark"
                      ? `2px solid ${props.theme.palette.secondary.main}`
                      : `2px solid ${props.theme.palette.primary.main}`
                  }
                >
                  <div className="card-number-wrapper">
                    <Box
                      className='card-number'
                      backgroundColor={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.primary.main
                      }
                    >
                      <h1>4</h1>
                    </Box>
                  </div>
                  <div className="card-text-container">
                    <Typography
                      fontSize={'1.25rem'}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      <span>We Close On Your Timeline</span>
                    </Typography>
                    <Typography mt={1.25}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      Once you accept our cash offer, we can close on your timeline. Whether you need to
                      close in a few days or a few months, we work with you to make it happen.
                      <br />
                      <br />
                      Our local team will handle all the paperwork and logistics, ensuring a smooth and easy
                      process. Because we’re a cash buyer, there’s no waiting for bank approvals or
                      financing — we can close in as little as 7 days, or whenever works best for you.
                    </Typography>
                  </div>
                </Box>
                <Box
                  className='flex-card'
                  border={
                    props.theme.palette.mode === "dark"
                      ? `2px solid ${props.theme.palette.secondary.main}`
                      : `2px solid ${props.theme.palette.primary.main}`
                  }
                >
                  <div className="card-number-wrapper">
                    <Box
                      className='card-number'
                      backgroundColor={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.secondary.main
                          : props.theme.palette.primary.main
                      }
                    >
                      <h1>5</h1>
                    </Box>
                  </div>
                  <div className="card-text-container">
                    <Typography
                      fontSize={'1.25rem'}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      <span>Get Paid</span>
                    </Typography>
                    <Typography mt={1.25}
                      color={
                        props.theme.palette.mode === "dark"
                          ? props.theme.palette.common.white
                          : props.theme.palette.primary.main
                      }
                    >
                      Once the paperwork is complete and the sale is closed, you’ll receive your payment on
                      the day of closing. Since there is no bank involved, you do not have to worry about any
                      bank approvals or hangups on the day of closing— we make sure you get your money
                      quickly so you can move forward with your next chapter.
                    </Typography>
                  </div>
                </Box>
              </div>
            </section>

          </Box>
        </div>
      </Box>

      <section
        className="sell-my-home"
        style={{
          backgroundColor:
            props.theme.palette.mode === "dark"
              ? props.theme.palette.secondary.main
              : props.theme.palette.primary.main,
          padding: '10em 5em 10em 5em',
          textAlign: 'center'
        }}
      >

        <Box>
          <Typography
            sx={{
              color:
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.primary.main
                  : props.theme.palette.common.white,
              fontSize: "2rem",
              fontFamily: "karla",
            }}
          >
            Ready to sell? Let us make you an offer today.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "2.5em" }}>
          <Button
            variant="contained"
            color="warning"
            sx={{ padding: "15px 25px" }}
            onClick={() => trackEvent('CTA', 'Click', 'Get Your Cash Offer Now')}
          >
            <Link className="link-item" to="/contact">
              <Typography
                sx={{
                  color:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.common.white,
                  fontFamily: "latoBold",
                  fontSize: "1.15rem",
                }}
              >
                Get your Cash Offer Now
              </Typography>
            </Link>
          </Button>
        </Box>
      </section>

      <div className="content-wrapper">
        <Box mb={15}>
          <Typography
            variant="h4"
            fontFamily={"karla"}
            mb={2.5}
            mt={15}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.secondary.light
                : props.theme.palette.primary.dark
            }
          >
            Supplemental Services
          </Typography>
          <Typography
            lineHeight={2}
            color={
              props.theme.palette.mode === "dark"
                ? props.theme.palette.common.white
                : props.theme.palette.primary.dark
            }
          >
            In addition to our core focus of purchasing properties directly, our team offers supplemental services tailored to specific needs:
          </Typography>
          <Box>
            <ul>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Realtor Services - </b>
                  </span>
                  If you're considering selling your home through the traditional market, our team can assist with listing your property and navigating the sales process. While our primary focus is on purchasing homes, this service is available for homeowners who prefer a traditional approach.
                </Typography>
              </li>
              <li>
                <Typography
                  lineHeight={2.5}
                  color={
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.common.white
                      : props.theme.palette.primary.dark
                  }
                >
                  <span>
                    <b>Construction Services for Investors - </b>
                  </span>
                  We provide expert construction management services exclusively for real estate investors. Whether you're a seasoned investor or new to the market, we can oversee renovation projects to maximize value and streamline the process. This service is ideal for out-of-state investors looking to expand their portfolio in the Indianapolis area without the hassle of managing projects locally.                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        <Box
          bgcolor={
            props.theme.palette.mode === "dark"
              ? props.theme.palette.primary.light
              : props.theme.palette.secondary.main
          }
          sx={{
            borderRadius: "25px 25px 0 0",
          }}
        >
          <Box className="contact-us-text-container">
            <Typography variant="h4" fontFamily={"karla"}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.secondary.dark
                  : props.theme.palette.primary.main
              } textAlign={'center'}
            >
              Sell Your Indiana Home Fast with CarpHomes
            </Typography>
            <Typography lineHeight={2.5} mt={2}
              color={
                props.theme.palette.mode === "dark"
                  ? props.theme.palette.common.white
                  : props.theme.palette.primary.dark
              } textAlign={'center'}
            >
              We make selling your home easy, fast, and stress-free. As a local Indiana business, we offer cash purchases with no realtor commissions or closing costs. Close in as little as 7 days—no repairs, no open houses, no hassle. Enjoy a private, personalized experience with a team that understands your needs every step of the way.
            </Typography>

            <Box display="flex" justifyContent="center" mt={5}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    props.theme.palette.mode === "dark"
                      ? props.theme.palette.warning.main
                      : props.theme.palette.primary.main,
                }}
              >
                <Link className="link-item" to="/contact">
                  <Typography color={props.theme.palette.common.white}>
                    Reach Out
                  </Typography>
                </Link>
              </Button>
            </Box>
          </Box>
          {/* Joy@Carphomes.net
              317-812-1388 */}
        </Box>
      </div>
      <Footer />
    </Box>
  );
};

export default SellMyHome;
